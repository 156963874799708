/* eslint-disable */

export default class DateInfo {
    public year: number | null = null;
    public month: number | null = null;
    public day: number | null = null;
    public fullYears: number | null = null;

    constructor(val: string, start: DateInfo | null = null) {
        const dateArr: any = val.split('T')[0].split('-');

        if (dateArr.length === 3) {
            this.year = +dateArr[0];
            this.month = +dateArr[1];
            this.day = +dateArr[2];


            if (!start) {
                const todayArr: any = DateInfo.now().split('T')[0].split('-');

                const today = {
                    year: +todayArr[0],
                    month: +todayArr[1],
                    day: +todayArr[2]
                };

                if (!(this.year && this.month && this.day)) {
                    return;
                }

                this.fullYears = today.year - this.year;

                if ((today.month < this.month) || (today.month === this.month && today.day < this.day)) {
                    this.fullYears -= 1;
                }
            } else if (start.year && start.month && start.day) {
                this.fullYears = this.year - start.year;

                if ((start.month > this.month) || (start.month === this.month && start.day > this.day)) {
                    this.fullYears -= 1;
                }
            }
        }
    }

    public beforeToday = (): boolean | null => {
        const today = new DateInfo(DateInfo.now());

        if (!(today.year && today.month && today.day && this.year && this.month && this.day)) {
            return null;
        }

        if (today.year > this.year) {
            return true;
        }

        if (today.year === this.year) {
            return today.month > this.month || (today.month === this.month && today.day > this.day);
        }

        return false;
    }

    // eslint-disable-next-line
    /* tslint:disable-next-line */
    static now = (): string => {
        const now = new Date();

        const timezone = now.getTimezoneOffset() * 60000;
        const normalDate = (new Date(+(new Date()) - timezone)).toISOString().slice(0, -1);

        return normalDate;
    }

    // eslint-disable-next-line
    /* tslint:disable-next-line */
    static between = (start: DateInfo, end: DateInfo): number => {
        if (!(start && end && start.year && start.month && start.day && end.year && end.month && end.day)) {
            return 0;
        }

        const startDate = +new Date(start.year, start.month, start.day);
        const endDate = +new Date(end.year, end.month, end.day);

        const diff = Math.ceil((endDate - startDate) / 1000 / 60 / 60 / 24);

        return diff || 0;
    }
}
