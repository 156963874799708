










































import Vue from 'vue';

import DatePicker from '@/components/DatePicker.vue';
import DateInfo from '@/helpers/dateInfo';

export default Vue.extend({
    name: 'Events',
    data() {
        const today = new Date();
        const monthCalc = today.getMonth();
        const year = today.getFullYear();
        const month = ('0' + (monthCalc)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        let secondYear = year;
        let secondMonth = ('0' + (monthCalc + 1)).slice(-2);

        if (month === '12') {
            secondYear += 1;
            secondMonth = '01';
        }

        let first = '';
        let second = '';

        first += `${year}-${month}-${day}`;
        second += `${secondYear}-${secondMonth}-${day}`;

        return {
            params: {
                from: first,
                to: second
            },
            datesError: ''
        };
    },
    computed: {
        downloadLink(): string {
            return `/report_user_external?params={"from":"${this.params.from}","to":"${this.params.to}"}`;
        }
    },
    watch: {
        params: {
            handler() {
                this.checkDates();
            },
            deep: true
        }
    },
    methods: {
        checkDates() {
            if (this.params.from > this.params.to) {
                this.datesError = 'Начальная дата должна быть меньше конечной';
            } else {
                this.datesError = '';

                const from = new DateInfo(this.params.from);
                const to = new DateInfo(this.params.to);

                if (from && to && DateInfo.between(from, to) > 31) {
                    this.datesError = 'Период не должен быть больше 31 дня';
                }
            }
        }
    },
    components: {
        DatePicker
    }
});
